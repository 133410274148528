import axios from 'axios';
import {getAppLanguage} from "../../utils/translator";
import {uuid} from "../../utils/kidZoneManager";
import logger from "../../utils/logger";
import {CLIENT_ID} from "../../config";

export const DC = 'mdc';

//export const KIDS_BACK_PROD = 'https://iap.gameloft.com/beta/kids';
export const KIDS_BACK_PROD = 'https://iap.gameloft.com/kids';
export const IGP_CODE = 'KAWA';
export const KIDS_BACK_BETA_FED_AUTH = 'https://confirmation.gameloft.com/beta/kids/federation/auth/';
export const KIDS_BACK_BETA_FED_STORAGE = 'https://confirmation.gameloft.com/beta/kids/federation/storage/';

export const KIDS_BACK_GOLD_FED_AUTH = 'https://iap.gameloft.com/kids/federation/auth/';
export const KIDS_BACK_GOLD_FED_STORAGE = 'https://iap.gameloft.com/kids/federation/storage/';

export const TRACKING_URL = 'https://ludigames.gameloft.com/fr/sfr_fr_kids_webapp/tracking.php';


export const getCurrentUserCredentials = () => {
    let ecommId = localStorage.getItem('ecomm_id');
    return 'ecomm:' + ecommId
};


export const getMyProfile = (refreshLocalProfile) => {
    return new Promise(function (resolve, reject) {

        if (localStorage.getItem('profileInfo') && localStorage.getItem('onlineProfileIsOutDated') && refreshLocalProfile) {
            updateMyProfile(JSON.parse(localStorage.getItem('profileInfo'))).then(result => {
                if (result === "OK") {
                    localStorage.removeItem("onlineProfileIsOutDated");
                }
            }).catch(error => {
                reject('Something went wrong', error.request);
            });
        }

        if (localStorage.getItem('profileInfo') && refreshLocalProfile === undefined) {
            resolve(JSON.parse(localStorage.getItem('profileInfo')));
        } else {
            getFederationAccessToken().then(accessToken => {
                axios.get(KIDS_BACK_GOLD_FED_STORAGE + '?accessToken=' + accessToken + '&credential=' + getCurrentUserCredentials(),
                    {
                        headers: {
                            'X-Client-Id': CLIENT_ID,
                            'X-Federation-Datacenter': DC,
                        }
                    }).then(response => {
                    localStorage.setItem('profileInfo', JSON.stringify(response.data));
                    resolve(response.data);
                }).catch(error => {
                    logger.debug(error.request.status);

                    if (error.request && error.request.status === 401) {
                        localStorage.setItem('access_token_expired', 1);
                    }

                    if (error.request.status === 404) {
                        localStorage.setItem('profileInfo', '');
                        resolve('');
                    } else {
                        logger.debug('getMyProfile reject backend');
                        reject('Something went wrong', error.request);
                    }
                });
            });
        }

    });
};

export const getFederationAccessToken = () => {
    logger.debug('getFederationAccessToken fed helper');
    return new Promise(function (resolve, reject) {
        let accessToken = localStorage.getItem('access_token');
        let isExpired = localStorage.getItem('access_token_expired');
        if (accessToken && !isExpired) {
            resolve(accessToken);
        } else {
            let password = localStorage.getItem('auth_token');
            axios.post(KIDS_BACK_GOLD_FED_AUTH, {
                credentials: getCurrentUserCredentials(),
                password: password,
            }, {
                headers: {
                    'X-Client-Id': CLIENT_ID,
                    'X-Federation-Datacenter': DC,
                }
            }).then(response => {
                localStorage.setItem('access_token', response.data.access_token);
                localStorage.removeItem('access_token_expired');
                resolve(response.data.access_token)
            }).catch(error => {
                logger.debug('Authorization failed : ', error);
                if (error.response && error.response.status) {
                    if (error.response.status === 426) {
                        clearCacheAndReload();
                        reject('Update required');
                    }
                    if (error.response.status === 401) {
                        localStorage.removeItem('auth_token');
                        getAuthProfile().then(response => {
                            if (response.logout_url) {
                                window.location.href = response.logout_url;
                            }
                        }).catch(error => {
                            logger.debug(error);
                        });
                        reject('SFR auth required');
                    }
                }
                reject('Something went wrong');
            });

        }
    });
};

export const updateMyProfile = (fieldsToBeUpdated) => {
    logger.debug('updateMyProfile fed helper', fieldsToBeUpdated);
    updateLocalCachedProfile(fieldsToBeUpdated);
    return new Promise(function (resolve, reject) {
        getFederationAccessToken().then(accessToken => axios.put(KIDS_BACK_GOLD_FED_STORAGE, {
                credential: getCurrentUserCredentials(),
                accessToken: accessToken,
                profileFieldsToUpdate: fieldsToBeUpdated,
            },
            {
                headers: {
                    'X-Client-Id': CLIENT_ID,
                    'X-Federation-Datacenter': DC,
                }
            })).then(response => {
            if (response.data.response === "OK") {
                if (localStorage.getItem("onlineProfileIsOutDated")) {
                    localStorage.removeItem("onlineProfileIsOutDated");
                    updateMyProfile(JSON.parse(localStorage.getItem('profileInfo')))
                }
                resolve("OK");
            } else {
                reject("Can't save profile");
            }
        }).catch(error => {
            logger.debug('remote call error : ', error);
            localStorage.setItem('onlineProfileIsOutDated', "true");
            if (error.request && error.request.status === 401) {
                localStorage.setItem('access_token_expired', 1);
            }
            reject(error);
        });
    });
};

export const sendFeedback = (feedback) => {
    return new Promise(function (resolve, reject) {
        getFederationAccessToken().then(accessToken =>
            axios.post(KIDS_BACK_PROD + '/' + IGP_CODE + '/feedback', feedback, {
                headers: {
                    'X-Client-Id': CLIENT_ID,
                    'X-Federation-Datacenter': DC,
                    'X-Federation-Token': accessToken,
                }
            })
        ).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
};


export const sendGLOTEvent = (eventData) => {
    return new Promise(function (resolve, reject) {
        let trackingData = JSON.stringify(prepareTrackingData(eventData));
        logger.debug('trackingData', trackingData);
        axios.post(TRACKING_URL, trackingData).then(response => {
            logger.debug('GLOT response', response);
            resolve(response)
        }).catch(error => {
            logger.debug(error);
            reject('Something went wrong');
        });

    });
};
const prepareTrackingData = (events) => {
    return {
        ggi: 78061,
        uuid: uuid(),
        entity_type: "SfrFrKidsWebApp",
        entity_id: CLIENT_ID,
        proto_ver: "v1",
        ts: Math.floor(Date.now() / 1000),
        events: events
    };
};
export const sendPinResetRequest = () => {
    return new Promise(function (resolve, reject) {
        getFederationAccessToken().then(accessToken => {
                let ecommId = localStorage.getItem('ecomm_id');
                let credentials = 'ecomm:' + ecommId;
                return axios.post(KIDS_BACK_PROD + '/users/' + credentials + '/pin-reset-message', '', {
                    headers: {
                        'X-Client-Id': CLIENT_ID,
                        'X-Federation-Datacenter': DC,
                        'X-Federation-Token': accessToken,
                        'Accept': 'application/vnd.gameloft.kids-v2.0.0+json',
                        'Accept-Language': getAppLanguage()
                    }
                })
            }
        ).then(response => {
            if (response.status === 204) {
                getMyProfile(true).then(profileInfo => {
                    logger.debug('sendPinResetRequest update profile success');
                }).catch(error => {
                    logger.debug('sendPinResetRequest update profile failed');
                });
            }
            response.status ? resolve(response.status) : resolve(500);
        }).catch(error => {
            error.request && error.request.status ? resolve(error.request.status) : resolve(500);
        });
    });
};

const updateLocalCachedProfile = (fieldsToBeUpdated) => {
    let currentProfile = localStorage.getItem('profileInfo');
    let updatedProfile = '';
    if (currentProfile !== '') {
        currentProfile = JSON.parse(currentProfile);
        updatedProfile = {...currentProfile, ...fieldsToBeUpdated};
    } else {
        updatedProfile = {...fieldsToBeUpdated};
    }
    logger.debug('updateLocalCachedProfile', updatedProfile);
    localStorage.setItem('profileInfo', JSON.stringify(updatedProfile));
};

export const getAuthProfile = () => {
    return new Promise(function (resolve, reject) {
        axios.get(KIDS_BACK_PROD + '/' + IGP_CODE + '/authentication-profile?app_type=web',
            {headers: {Accept: "application/vnd.gameloft.kids-v2.0.0+json"}}
        ).then(response => {
            resolve(response.data);
        }).catch(error => {
            logger.debug(error);
            reject(error);
        });

    });
};

export const clearCacheAndReload = () => {
    let now = new Date().getTime();
    if (localStorage.getItem('cacheCleared') < (now - 60 * 60)) {
        if ('serviceWorker' in navigator) {
            if (navigator.serviceWorker.controller) {
                navigator.serviceWorker.controller.postMessage('clear_cache');
            }
            navigator.serviceWorker.getRegistrations().then(function (registrations) {
                for (let registration of registrations) {
                    registration.update()
                }
            })
        }

        //save errors for back-end logging
        let errors = localStorage.getItem('pendingErrors');
        localStorage.clear();
        localStorage.setItem('pendingErrors', errors);
        localStorage.setItem('cacheCleared', new Date().getTime());

        window.location.reload(true);

    }
};