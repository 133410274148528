import React, {Component, Fragment} from 'react';
import styles from './Auth.module.css';
import Button from '../../components/UI/Button/Button'
import WelcomeBg from '../../assets/images/home-illustration.png';
import WelcomeBgDesktop from '../../assets/images/home-illustration-desktop.png';
import {connect} from "react-redux";
import * as actions from '../../store/actions/index';
import {Redirect} from 'react-router-dom';
import Spinner from "../../components/UI/Spinner2/Spinner";
import queryString from "query-string";
import Modal from "../../components/UI/Modal/Modal";
import {text} from "../../utils/translator";
import logger from "../../utils/logger";
import {getIOSVersion} from "../../utils/IOS";
import appIcon from "../../assets/images/app-icon.png";
import appIconDesktop from "../../assets/images/app-icon-desktop.png";

const NodeRSA = require('node-rsa');
const PublicKey = "-----BEGIN PUBLIC KEY-----\n" +
    "MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA5Ih6gVnTFi4H/T81QGdf\n" +
    "HDMEsHJZBQCsTeNTULawM3lY/ZGwlvZSfDzxo8bbDDRMR6QuRtAqacSoSeQJIi0R\n" +
    "QTQaaZ47DGjxldmqLUaOMEXUWrxCkdT5YE5tg6sT9E6/xKf1j0UYoFUdxEXRM6af\n" +
    "U8McOy4iygo3SogtY/NGXm67DCRbhrlcwK6WD7mEl2OAfzwGTHq8gj5zDv/89TF8\n" +
    "txjUX4DBVX4Qr6j73of4PIXHdiiwELdGun0J0ijbuFVM0xxnqB1fny5jeZGEQjhJ\n" +
    "uzXFEwdyHYuRKPgCx9y9xI2lYo/S3DV2ovR7rGayXbDib4x8WJTWCuLr6BL7AoAd\n" +
    "5x0Zc5dM5Yxb/yEG4Kv0GnCZoF8KVTnRboxREcpt8VrXcmTkJF/GL56tOwAHHtct\n" +
    "IKutdQF+mFUKevudQIPMnc+T49xdP0JhwpuYK5fTXScZ44HXOmZPne0ovrdA1sbh\n" +
    "S6WPCE77DqOI5MRrdbM3IRHfbcyBWSSeByIWzkgYvLvUte5++sskR1T5Od4oDmN1\n" +
    "RygNVedY9Hd2XDAED9ZMpvsOPRfyzRqQF4hJiki3VTMeHX+kn0Y1b/1zg8Pxn2VU\n" +
    "DswvlOP5A+qLr8DPAsfVxIoOHUnphqJ6UAK5FxxHwxt4j3HYtV5SuhVkgeiYeRLI\n" +
    "yTp6qVSAJkeKpvao+mWR5vkCAwEAAQ==\n" +
    "-----END PUBLIC KEY-----";

export class Auth extends Component {
    state = {
        modal: {
            opened: false,
            modalAuthFailed: false
        }
    };
    authHandler = () => {
        if(window.innerWidth > 1200){
            window.location.href = ' https://www.sfr.fr/espace-client/options/option/SW6Q#sfrclicid=EC_mire_Me-Connecter';
        } else {
            this.props.onAuth();
        }
    };

    componentDidMount() {
        if (this.props.location) {
            let params = queryString.parse(this.props.location.search);
            if (params.glka_auth) {
                try {
                    const key = new NodeRSA(PublicKey);
                    let decrypted = key.decryptPublic(params.gameloft_data, 'json');
                    logger.debug('Auth tag decrypted : ', decrypted);
                    let expiration = new Date(decrypted.expiration_date.replace(' ', 'T'));
                    let month = ("0" + (expiration.getMonth() + 1)).slice(-2);
                    let day = ("0" + expiration.getDate()).slice(-2);
                    let expiration_formatted = expiration.getFullYear() + "-" + month + "-" + day;
                    logger.debug('expiration_formatted : ', expiration_formatted);
                    localStorage.setItem('sub_expiration_date',expiration_formatted);
                    this.props.authDone(params.token, decrypted.ecomm_user_id, decrypted.auth_duration, decrypted.sub_client_id, decrypted.price);
                } catch (e) {
                    this.setState({modalAuthFailed: true});
                }
            }
        }
        let isAddToHomeScreenShowed = localStorage.getItem('isAddToHomeScreenShowed');
        let version = getIOSVersion();

        let isAppModeSupportRedirects = false;

        if(version){
            if(version[0] === 12 && version[1] >= 2){
                isAppModeSupportRedirects = true;
            }
            if(version[0] > 12){
                isAppModeSupportRedirects = true;
            }
        }
        if (!isAddToHomeScreenShowed && isAppModeSupportRedirects) {
            localStorage.setItem('isAddToHomeScreenShowed', 'true');
            this.setState({modal: {opened: true}});
        }
    }

    modalCancelHandler = () => {
        logger.debug('modalCancelHandler');
        this.setState({modal: {opened: false}, error: ''});
    };

    render() {
        logger.debug('Auth.js render', this.props);
        let authRedirect = null;
        if (this.props.isAuthenticated) {
            authRedirect = <Redirect to="/kids"/>;
        }

        let buttonCaption = text('Connect');
        if (this.props.loading) {
            buttonCaption = <Spinner/>
        }

        let homeScreenModal = (<Modal
            show={this.state.modal.opened}
            modalClosed={this.modalCancelHandler}
            modalTitle={false}
            actionButonTitle={text("learn_more")}
            actionButtonHandler={() => {
                this.props.history.push('/help/tips/4');
            }}
            hasCancelButton={false}
            headerCloseButton={true}
            headerImage={true}
            modalTitleStyle={{display: 'none'}}
            position={"top"}
        >
            <p style={{fontWeight: 'bold'}}>{text('add_home_modal_start')}</p>
        </Modal>);

        let authFailedModal = null;
        if (this.state.modalAuthFailed) {
            authFailedModal = (<Modal
                show={true}
                modalTitle={false}
                actionButonTitle={text("OK")}
                actionButtonHandler={() => {
                    this.setState({modalAuthFailed: false});
                    this.props.history.push('/');
                }}
                modalTitleStyle={{display: 'none'}}
                position={"top"}
            >
                <p style={{fontWeight: 'bold'}}>{text('auth_failed_message')}</p>
            </Modal>);
        }


        if ('standalone' in navigator && navigator.standalone && (/iphone|ipod|ipad/gi).test(navigator.platform) && (/Safari/i).test(navigator.appVersion)) {
            homeScreenModal = '';
        }

        let isDesktop = window.innerWidth > 1200;
        let icon = isDesktop ? appIconDesktop : appIcon;
        let backImage = isDesktop ? WelcomeBgDesktop : WelcomeBg;

        return (
            <div className={styles.WelcomeScreen} style={{backgroundImage: 'url('+(backImage)+')'}}>
                {authRedirect}
                <Fragment>
                    <img src={icon} alt="" className={styles.appIcon}/>
                </Fragment>
                <h1 className={styles.SafePlace}>{text('Welcome_IntroduceFirst')}</h1>
                <div className={styles.DesktopWelcomeDescription}>{text('desktop_version_welcome')}</div>
                <Button clicked={this.authHandler} btnType="StickToBottom">{buttonCaption}</Button>
                {homeScreenModal}
                {authFailedModal}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        loading: state.auth.loading,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onAuth: () => dispatch(actions.auth()),
        setTermsWasAccepted: () => dispatch(actions.setTermsWasAccepted()),
        authDone: (token, ecommId, expiresIn, clientId, price) => dispatch(actions.authDone(token, ecommId, expiresIn, clientId, price)),
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Auth);
