import * as actionTypes from './actionTypes';
import * as axiosHelper from "./axiosHelper";
import {setModalsModeNewUser} from "./parentZone"
import logger from "../../utils/logger";

export const registerStart = () => {
    return {
        type: actionTypes.REGISTER_START
    }
};

export const registerSuccess = () => {
    localStorage.setItem('registerState', 'codeVerification');
    return {
        type: actionTypes.REGISTER_SUCCESS,
    }
};
export const registerFailed = (error) => {
    return {
        type: actionTypes.REGISTER_FAILED,
        error: error
    }
};
export const updateProfileStart = () => {
    return {
        type: actionTypes.UPDATE_PROFILE_START
    }
};

export const updateProfileSuccess = () => {
    logger.debug('updateProfileSuccess');
    return {
        type: actionTypes.UPDATE_PROFILE_SUCCESS,
    }
};
export const updateProfileFailed = (error) => {
    return {
        type: actionTypes.UPDATE_PROFILE_FAILED,
        error: error
    }
};

export const resetRegisterState = () => {
    return {
        type: actionTypes.REGISTER_RESET_STATE,
    }
};
export const createPinStart = () => {
    return {
        type: actionTypes.REGISTER_CREATE_PIN_START,
    }
};
export const clearRegError = () => {
    return {
        type: actionTypes.REGISTER_CLEAR_ERROR,
    }
};

export const createPinSuccess = () => {
    localStorage.setItem('isLoggedIn', 'true');
    localStorage.setItem('alreadyLoggedBefore', 'true');
    localStorage.setItem('hasAccount', 1);

    return dispatch => {
        dispatch(setModalsModeNewUser());
        dispatch({
            type: actionTypes.REGISTER_CREATE_PIN_SUCCESS,
        });
    }
};



export const createPinFailed = (error) => {
    return {
        type: actionTypes.REGISTER_CREATE_PIN_FAILED,
        error: error
    }
};
export const register = (email, password) => {
    return dispatch => {
        dispatch(registerStart());
        axiosHelper.updateMyProfile({
            email: email,
            password: password,
        }).then(response => {
            logger.debug(response);
            if (response === "OK") {
                dispatch(registerSuccess());
            } else {
                dispatch(registerFailed(response));
            }
        }).catch(error => {
            dispatch(registerFailed(error));
        });
    };
};

export const createPin = (pin) => {
    return dispatch => {
        dispatch(createPinStart());

       axiosHelper.updateMyProfile({
                isTutorialEnded: false,
                parent: {pin: pin},
            }
        ).then(response => {
            logger.debug(response);
            if (response === "OK") {
                dispatch(createPinSuccess());
            } else {
                dispatch(createPinFailed('Something went wrong'));
            }
        }).catch(error => {
            logger.debug(error);
            dispatch(createPinFailed(error));
        });
    };
};
export const registerCheckState = () => {
    logger.debug('registerCheckState');
    return dispatch => {
        const registerState = localStorage.getItem('registerState');
        if (registerState !== '') {
            dispatch(setRegisterState(registerState));
        }
    };
};
export const setRegisterState = (registerState) => {
    switch (registerState) {
        case 'codeVerification':
            return {
                type: actionTypes.REGISTER_VERIFY_CODE_START,
            };
        case 'pinCreation':
            return {
                type: actionTypes.REGISTER_CREATE_PIN_START,
            };
        default:
            return {
                type: actionTypes.REGISTER_START,
            };
    }
};