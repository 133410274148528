import React, {Component} from 'react';
import {text} from "../../../utils/translator";
import Modal from "./Modal";
import {connect} from "react-redux";
import {sendPinResetRequest} from "../../../store/actions/axiosHelper";
import logger from "../../../utils/logger";


class ForgotPINModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: text('reset_pin_pre_inform'),
            onSubmit: this.resetClickHandler,
            submitText: text('reset_pin_reset'),
            cancelBtnPresent: true,
            attempts: 0
        }
    }

    resetClickHandler = (e) => {
        if (e) e.preventDefault();
        if (this.state.attempts > 3) {
            return;
        }
        logger.debug('resetClickHandler start ');
        sendPinResetRequest().then(status => {
                logger.debug('resetClickHandler status ',status);
                let title = text('reset_pin_error_default');
                switch (status) {
                    case 204 :
                        return this.setState({
                            title: text('reset_pin_sucess'),
                            onSubmit: this.props.closed,
                            submitText: text('OK'),
                            cancelBtnPresent: false
                        });
                    case 429 :
                        title = text('reset_pin_error_429');
                        break;
                    case 401 :
                        title = text('reset_pin_error_401');
                        break;
                    case 404:
                        title = text('reset_pin_error_404');
                        break;
                }
                return this.setState(current => {
                    return {
                        title: title,
                        onSubmit: this.resetClickHandler,
                        submitText: text('reset_pin_reset'),
                        cancelBtnPresent: true,
                        attempts: current.attempts + 1,
                    }
                });
            }
        );
    };


    render() {
        logger.debug('render ForgotPINModal',this.state,this.props);
        return (
            <Modal
                show={true}
                modalTitle={this.state.title}
                actionButonTitle={this.state.submitText}
                actionButtonHandler={this.state.onSubmit}
                hasCancelButton={this.state.cancelBtnPresent}
                modalClosed={this.props.closed}
                modalTitleStyle={{margin: '30px'}}
                cancelButtonOnRight={true}
                actionButtonStyle={this.state.cancelBtnPresent ? {} :{color:'black'}}
            >
            </Modal>
        );
    }

}


const mapStateToProps = state => {
    return {};
};
const mapDispatchToProps = dispatch => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPINModal);