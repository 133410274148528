import React, {Fragment} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import styles from './TopBar.module.css';
import {text} from "../../../utils/translator";

const topBar = (props) => {
    let rightButton = null;
    if (props.hasHomeButton) {
        rightButton = (
            <div className={styles.RightButton} onClick={() => props.backToHandler('/')}>
                <span className={'icons icon-home'} alt='games'></span>
            </div>
        );
    }
    if (props.hasTutorialButton) {
        rightButton = (
            <div onClick={() => props.tutorialBtnClickHandler()} className={styles.RightButton}
                 style={{color: '#F1696B', fontSize: '16px', top: '22px'}}>
                <FontAwesomeIcon icon={faQuestionCircle}/>
                {text('onboading_tutorial')}
            </div>
        );
    }
    let titleClass = rightButton ? styles.PageTitle : styles.PageTitleLong;
    return (
        <Fragment>
            <div className={styles.BackLink}>
                <div onClick={() => props.backToHandler(props.backTo)} className={styles.BackArrow}><span className={'icons icon-arrow-back'} alt='games'></span></div>
                <div className={titleClass}>{props.pageTitle}</div>
                {rightButton}
            </div>
        </Fragment>
    );
};

export default topBar;