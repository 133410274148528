import * as actionTypes from './actionTypes';
import * as axiosHelper from "./axiosHelper";
import {updateProfileSuccess} from "./profile";
import {getKidsProfileSuccess} from "./kidZone";

export const startCreatingKidProfile = () => {
    return {
        type: actionTypes.KID_PROFILE_CREATE_START,
    }
};
export const updateKidProfile = (profile, updateProfileId) => {
    return {
        type: actionTypes.KID_PROFILE_CREATE_UPDATE,
        kidProfile: profile,
        updateProfileId: updateProfileId
    }
};
export const startEditKidProfile = (profile, updateProfileId) => {
    return {
        type: actionTypes.KID_PROFILE_EDIT,
        kidProfile: profile,
        updateProfileId: updateProfileId
    }
};

export const saveKidsProfilesStart = (kidsProfiles) => {
    return {
        type: actionTypes.SAVE_KIDS_PROFILE_START,
        kidsProfiles: kidsProfiles ? kidsProfiles : [],
    }
};
export const clearPinVerification = () => {
    return {
        type: actionTypes.PIN_VERIFICATION_CLEAR,
    }
};
export const profileEditCancel = () => {
    return {
        type: actionTypes.UPDATE_PROFILE_EDIT_CANCEL,
    }
};
export const saveKidsProfileSuccess = (profiles, newProfile) => {
    return {
        type: actionTypes.SAVE_KIDS_PROFILE_SUCCESS,
        kidsProfiles: profiles,
        newProfile: newProfile
    }
};
export const saveKidsProfileFailed = () => {
    return {
        type: actionTypes.SAVE_KIDS_PROFILE_FAILED,
    }
};

export const saveKidProfile = (kidProfile, currentProfiles, updateProfileId) => {
    return dispatch => {
        let timestamp =  Math.floor(Date.now() / 1000);
        if (updateProfileId === false || updateProfileId === undefined) {
            kidProfile.id = timestamp;
        }
        let kids = {};
        kids[timestamp] = kidProfile;
        if(currentProfiles){
            kids = {...currentProfiles, ...kids};
        }

        if (updateProfileId !== false && updateProfileId !== undefined) {
            currentProfiles[updateProfileId] = kidProfile;
            kids = currentProfiles;
        }
        Object.keys(kids).forEach(profile => {
            if(kids[profile] === null) {
                delete kids[profile];
            }
        })
        console.log(kids);
        
        dispatch(saveKidsProfilesStart(kids));

        axiosHelper.updateMyProfile({kids}).then(response => {
            if (response === "OK") {
                dispatch(saveKidsProfileSuccess(kids, !updateProfileId));
                dispatch(updateProfileSuccess({kids: kids}));
                dispatch(getKidsProfileSuccess(kids, updateProfileId));
            } else {
                dispatch(saveKidsProfileFailed());
            }
        }).catch(error => {
            dispatch(saveKidsProfileFailed());
        });
    };
};
export const verifyPinCode = (pin) => {
    return dispatch => {
        axiosHelper.getMyProfile().then(profileInfo => {
            console.log(profileInfo.parent.pin);
            if (profileInfo.parent.pin === pin) {
                dispatch(pinVerificationSuccess())
            } else {
                dispatch(pinVerificationFailed());
            }
        }).catch(error => {
            dispatch(pinVerificationFailed());
        });
    }
};

export const pinVerificationSuccess = () => {
    return {
        type: actionTypes.PIN_VERIFICATION_SUCCESS,
    }
};
export const pinVerificationFailed = () => {
    return {
        type: actionTypes.PIN_VERIFICATION_FAILED
    }
};
export const removeKidProfile = (currentProfiles, updateProfileId) => {
    return dispatch => {
        dispatch(saveKidsProfilesStart());

        currentProfiles[updateProfileId] = null;

        let kids = currentProfiles;

        if(Object.entries(kids).length === 0 && kids.constructor === Object){
            // Seshat bug fix for empty value
            kids = null;
        }

        axiosHelper.updateMyProfile({kids}).then(response => {
            if (response === "OK") {
                dispatch(saveKidsProfileSuccess(kids, false));
            } else {
                dispatch(saveKidsProfileFailed());
            }
        }).catch(error => {
            dispatch(saveKidsProfileFailed());
        });
    };
};

export const skipTutorial = () => {
    return {
        type: actionTypes.SKIP_TUTORIAL
    }
};

export const setModalsModeNone = () => {
    return {
        type: actionTypes.MODAL_MODE_NONE,
    }
};

export const setModalsModeNewUser = () => {
    return {
        type: actionTypes.MODAL_MODE_NEW_USER,
    }
};

export const setModalsModeDefault = () => {
    return {
        type: actionTypes.MODAL_MODE_DEFAULT,
    }
};

