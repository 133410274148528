import * as actionTypes from '../actions/actionTypes';
import {updateObject} from "../utility";

const initialState = {
    pinSaved: false,
    codeVerification: false,
    pinCreation: false,
    registration: true,
    loading: false,
    justRegistered: false,
    profileWasUpdated: false,

};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.REGISTER_START: return updateObject(state, { registration: true, loading: false });
        case actionTypes.REGISTER_FAILED: return updateObject(state,{ error: action.error, loading: false });
        case actionTypes.REGISTER_SUCCESS: return updateObject(state, {
            error: null, codeVerification: true,
            registration: false, loading: false,
            justRegistered: true
        });

        case actionTypes.UPDATE_PROFILE_START: return updateObject(state, { loading: true, error: null, profileWasUpdated: false });
        case actionTypes.UPDATE_PROFILE_FAILED: return updateObject(state,{ error: action.error, loading: false, profileWasUpdated: false });
        case actionTypes.UPDATE_PROFILE_SUCCESS: return updateObject(state, {
            error: null, loading: false, profileWasUpdated: true
        });

        case actionTypes.REGISTER_CLEAR_ERROR: return updateObject(state, {error: null});

        case actionTypes.REGISTER_VERIFY_CODE_START: return updateObject(state, { codeVerification: true, loading: false });
        case actionTypes.REGISTER_VERIFY_CODE_FAILED: return updateObject(state,{ error: action.error, loading: false });
        case actionTypes.REGISTER_VERIFY_CODE_SUCCESS: return updateObject(state, {error: null, pinCreation: true, codeVerification: false, loading: false});
        case actionTypes.REGISTER_CREATE_PIN_START: return updateObject(state, { pinCreation: true, loading: false });
        case actionTypes.REGISTER_CREATE_PIN_FAILED: return updateObject(state,{ error: action.error, loading: false });
        case actionTypes.REGISTER_CREATE_PIN_SUCCESS: return updateObject(state, {
            error: null,
            pinCreation: false,
            codeVerification: false,
            loading: false,
            pinSaved: true,
            justRegistered: true
        });
        case actionTypes.REGISTER_RESET_STATE: return updateObject(state, {justRegistered: false, pinSaved: false});
        default: return state;
    }
};

export default reducer;