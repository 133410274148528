import React, {Component, Fragment} from 'react';
import styles from './Modal.module.css';
import Backdrop from '../Backdrop/Backdrop';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import appIcon from '../../../assets/images/app-icon.png';
import {text} from "../../../utils/translator";


class Modal extends Component {
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps.show !== this.props.show || nextProps.children !== this.props.children || nextProps.modalTitle !== this.props.modalTitle;
    }

    render() {
        let buttons = null;
        let header = null;
        if (this.props.headerCloseButton) {
            header = (
                <div className={styles.modalHeader}>
                    <img src={appIcon} alt=""/>
                    <div className={styles.closeModal} onClick={this.props.modalClosed}>
                        <FontAwesomeIcon style={{marginTop: '13px'}} icon={faTimes}/>
                    </div>
                </div>
            );
        }

        let actionButtonStyle = this.props.hasCancelButton ?
            this.props.cancelButtonOnRight ? {width: '50%', borderRight: '1px solid lightgray'} : {width: '50%'}
            : {width: '100%'};

        let cancelButtonStyle = this.props.cancelButtonOnRight ? {width: '50%', borderRight: 'none'} : {width: '50%'};

        actionButtonStyle = {...actionButtonStyle, ...this.props.actionButtonStyle};
        cancelButtonStyle = {...cancelButtonStyle, ...this.props.cancelButtonStyle};

        let cancelButton = this.props.hasCancelButton ?
            (<div
                className={styles.CancelButton}
                style={cancelButtonStyle}
                onClick={this.props.modalClosed}>
              {text('Cancel')}
            </div>) : '';

        let actionButton = (<div
            className={styles.ActionButton}
            onClick={this.props.actionButtonHandler}
            style={actionButtonStyle}>
            {this.props.actionButonTitle}
        </div>);


        buttons = (
            <Fragment>
                {cancelButton}
                {actionButton}
            </Fragment>
        );

        if(this.props.cancelButtonOnRight){
            buttons = (
                <Fragment>
                    {actionButton}
                    {cancelButton}
                </Fragment>
            );
        }

        let modalStyles = {
            display: this.props.show ? 'block' : 'none'
        };
        if (this.props.position === "top") {
            modalStyles = {
                top: '25%',
                display: this.props.show ? 'block' : 'none'
            };
        }

        modalStyles = {...modalStyles, ...this.props.modalStyles};

        let title = null;
        if (this.props.modalTitle !== undefined) {
            title = (<div className={styles.ModalTitle} style={this.props.modalTitleStyle}>{this.props.modalTitle}</div>);
        }

        let ModalBodyStyle = {};

        if (this.props.modalTitle === undefined) {
            ModalBodyStyle = {marginTop: '10px'};
        }

        return (
            <Fragment>
                <Backdrop
                    show={this.props.show}
                    clicked={this.props.hasCancelButton ? this.props.modalClosed : null}
                />
                <div className={styles.Modal} style={modalStyles}>
                    {header}
                    {this.props.modalImage !== undefined ? this.props.modalImage : ''}
                    {title}
                    <div className={styles.ModalBody} style={ModalBodyStyle}>
                        {this.props.children}
                    </div>
                    <div className={styles.ModalFooter} style={this.props.footerStyle}>
                        {buttons}
                    </div>
                </div>
            </Fragment>
        );
    }

}

export default Modal;