export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILED = 'AUTH_FAILED';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';
export const TERMS_ACCEPTED = 'TERMS_ACCEPTED';

export const API_CALL_START = 'API_CALL_START';
export const API_CALL_FAILED = 'API_CALL_FAILED';

export const REGISTER_START = 'REGISTER_START';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILED = 'REGISTER_FAILED';

export const REGISTER_VERIFY_CODE_START = 'REGISTER_VERIFY_CODE_START';
export const REGISTER_VERIFY_CODE_SUCCESS = 'REGISTER_VERIFY_CODE_SUCCESS';
export const REGISTER_VERIFY_CODE_FAILED = 'REGISTER_VERIFY_CODE_FAILED';

export const REGISTER_CREATE_PIN_START = 'REGISTER_CREATE_PIN_START';
export const REGISTER_CREATE_PIN_SUCCESS = 'REGISTER_CREATE_PIN_SUCCESS';
export const REGISTER_CREATE_PIN_FAILED = 'REGISTER_CREATE_PIN_FAILED';
export const REGISTER_CLEAR_ERROR = 'REGISTER_CLEAR_ERROR';
export const REGISTER_RESET_STATE = 'REGISTER_RESET_STATE';

export const GET_PROFILE_START = 'GET_PROFILE_START';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAILED = 'GET_PROFILE_FAILED';

export const UPDATE_PROFILE_START = 'UPDATE_PROFILE_START';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILED = 'UPDATE_PROFILE_FAILED';
export const UPDATE_PROFILE_CLEAR_ERROR = 'UPDATE_PROFILE_CLEAR_ERROR';
export const UPDATE_PROFILE_EDIT_CANCEL = 'UPDATE_PROFILE_EDIT_CANCEL';

export const KID_PROFILE_CREATE_START = 'KID_PROFILE_CREATE_START';
export const KID_PROFILE_CREATE_UPDATE = 'KID_PROFILE_CREATE_UPDATE';
export const KID_PROFILE_CREATE_SUCCESS = 'KID_PROFILE_CREATE_SUCCESS';
export const KID_PROFILE_CREATE_FAILED = 'KID_PROFILE_CREATE_FAILED';
export const KID_PROFILE_EDIT = 'KID_PROFILE_EDIT';
export const GET_FEATURES_SUCCESS = 'GET_FEATURES_SUCCESS';
export const GET_SUBSECTION_SUCCESS = 'GET_SUBSECTION_SUCCESS';
export const GET_SUBSECTION_START = 'GET_SUBSECTION_START';
export const GET_VIDEO_LIST_SUCCESS = 'GET_VIDEO_LIST_SUCCESS';
export const SAVE_KIDS_PROFILE_START = 'SAVE_KIDS_PROFILE_START';
export const SAVE_KIDS_PROFILE_SUCCESS = 'SAVE_KIDS_PROFILE_SUCCESS';
export const SAVE_KIDS_PROFILE_FAILED = 'SAVE_KIDS_PROFILE_FAILED';


export const PIN_VERIFICATION_SUCCESS = 'PIN_VERIFICATION_SUCCESS';
export const PIN_VERIFICATION_FAILED = 'PIN_VERIFICATION_FAILED';
export const PIN_VERIFICATION_CLEAR = 'PIN_VERIFICATION_CLEAR';

export const GET_SUBSCRIPTION_STATUS_STARTED = 'GET_SUBSCRIPTION_STATUS_STARTED';
export const GET_SUBSCRIPTION_STATUS_SUCCESS = 'GET_SUBSCRIPTION_STATUS_SUCCESS';
export const GET_SUBSCRIPTION_STATUS_FAILED = 'GET_SUBSCRIPTION_STATUS_FAILED';

export const SUBSCRIPTION_SUBSCRIBE_STARTED = 'SUBSCRIPTION_SUBSCRIBE_STARTED';
export const SUBSCRIPTION_SUBSCRIBE_SUCCESS = 'SUBSCRIPTION_SUBSCRIBE_SUCCESS';
export const SUBSCRIPTION_SUBSCRIBE_FAILED = 'SUBSCRIPTION_SUBSCRIBE_FAILED';

export const GET_SUBSCRIPTION_CLEAR_ERRORS = 'GET_SUBSCRIPTION_CLEAR_ERRORS';

export const SKIP_TUTORIAL = 'SKIP_TUTORIAL';


export const MODAL_MODE_NEW_USER = 'MODAL_MODE_NEW_USER';
export const MODAL_MODE_DEFAULT = 'MODAL_MODE_DEFAULT';
export const MODAL_MODE_NONE = 'MODAL_MODE_NONE';
//KID ZONE
export const GET_KID_PROFILE_START = 'GET_KID_PROFILE_START';
export const GET_KID_PROFILE_SUCCESS = 'GET_KID_PROFILE_SUCCESS';
export const GET_KID_PROFILE_FAILED = 'GET_KID_PROFILE_FAILED';

export const GET_GAMES_SUCCESS = 'GET_GAMES_SUCCESS';
export const GET_VIDEOS_SUCCESS = 'GET_VIDEOS_SUCCESS';
export const GET_VIDEO_SRC_START = 'GET_VIDEO_SRC_START';
export const GET_VIDEO_SRC_SUCCESS = 'GET_VIDEO_SRC_SUCCESS';
export const GET_STORIES_LIST_SUCCESS = 'GET_STORIES_LIST_SUCCESS';
export const GET_STORIES_SUCCESS = 'GET_STORIES_SUCCESS';
export const GET_STORY_SRC_START = 'GET_STORY_SRC_START';
export const GET_STORY_SRC_SUCCESS = 'GET_STORY_SRC_SUCCESS';
export const GET_PLAY_LINK_SUCCESS = 'GET_PLAY_LINK_SUCCESS';
export const GET_PLAY_LINK_START = 'GET_PLAY_LINK_START';

export const SEND_TRACKING_STARTED = 'SEND_TRACKING_STARTED';
export const SEND_TRACKING_SUCCESS = 'SEND_TRACKING_SUCCESS';
export const SEND_TRACKING_FAILED = 'SEND_TRACKING_FAILED';

export const FLASH_MESSAGE_TOGGLE = 'FLASH_MESSAGE_TOGGLE';
export const FLASH_MESSAGE_RESET = 'FLASH_MESSAGE_RESET';
export const FLASH_MESSAGE_SHOWN = 'FLASH_MESSAGE_SHOWN';

export const AUDIO_PLAY = 'AUDIO_PLAY';
export const AUDIO_PAUSE = 'AUDIO_PAUSE';
export const AUDIO_STOP = 'AUDIO_STOP';
export const AUDIO_PLAY_FAILED = 'AUDIO_PLAY_FAILED';
export const AUDIO_PAUSE_FAILED = 'AUDIO_PAUSE_FAILED';


