export const getIOSVersion = () => {
    let version = null;
    if (/iP(hone|od|ad)/.test(navigator.platform)) {
        let v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
        version =  [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
    }

    return version;
};
export const isStoriesEnabled = () => {
    if(window.location.hostname === 'kidsjeux-mobile.sfr.fr') {
        return false;
    }
        let version = getIOSVersion();

    if(version){
        if((version[0] < 13 || (version[0] === 13 && version[1] < 2))){
            return false;
        }
    }
    return true;
}
