import React  from "react";
import styles from './Button.module.css';

const button = (props) => {
    let btn = (<button className={[styles.Button, styles[props.btnType]].join(' ')}
                       onClick={props.clicked}
                       disabled={props.disabled}
                       style={props.style}
    >{props.children}</button>);

    if(props.focused){
        btn = (
            <button className={[styles.Button, styles[props.btnType]].join(' ')}
                    onClick={props.clicked}
                    disabled={props.disabled}
                    style={props.style}
                    ref={el => el && el.focus()}
            >{props.children}</button>
        );
    }
    return btn;
};

export default button;