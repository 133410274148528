import React, {Component} from 'react';
import styles from './Error.module.css';
import Button from '../../components/UI/Button/Button'

import {clearCacheAndReload} from '../../store/actions/axiosHelper'

import {text} from "../../utils/translator";


export class Error extends Component {

    resetButtonHandler = () => {
        localStorage.removeItem('cacheCleared');
        clearCacheAndReload();
    };

    render() {
        return (
            <div className={styles.ErrorScreen}>
                <div className={styles.ErrorMessage}>
                    <div className={styles.RestartTitle}>{text('crash_screen_title')}</div>
                    {text('crash_screen_text')}
                </div>

                <Button
                    style={{
                        backgroundColor: 'transparent', color: 'rgba(241, 105, 107, 1)',
                        fontSize: '24px',
                        fontWeight: 'bold',
                        lineHeight: '24px'
                    }}
                    clicked={this.resetButtonHandler}
                    btnType="StickToBottom">{text('Continue')}</Button>
            </div>
        );
    }
}


export default Error;
