//export const CLIENT_ID = '4188:78061:1.3.0:android:shop';
export const CLIENT_ID = '4188:78061:1.2.0:android:shop';
export const VERSION = '1.2.0';
export const TERMS_URL = 'https://wapshop.gameloft.com/sfr_kids_fr/terms_and_conditions.html';

export const gameCategories = [
    {
        id: 0, //generic
        name_i18n: 'category_title_non',
        icon: 'icon-game-cat1',
        alias: 'all'
    },
    {
        id: 1,
        name_i18n: 'category_title_new',
        icon: 'icon-cat-featured',
        alias: 'wap_kidsapp_new'
    },
    {
        id: 2,
        name_i18n: 'category_title_top',
        icon: ' icon-cat-words',
        alias: 'wap_kidsapp_top'
    },
    {
        id: 3,
        name_i18n: 'game_cat_Animals',
        icon: 'icon-game-cat4',
        alias: 'wap_kidsapp_animals'
    },
    {
        id: 4,
        name_i18n: 'category_title_heroes',
        icon: 'icon-cat-heroes',
        alias: 'wap_kidsapp_heroes'
    },
    {
        id: 5,
        name_i18n: 'game_cat_Cars',
        icon: 'icon-game-cat3',
        alias: 'wap_kidsapp_racing'
    },
    {
        id: 6,
        name_i18n: 'Casual',
        icon: 'icon-game-casual',
        alias: 'wap_kidsapp_casual'
    },
    {
        id: 7,
        name_i18n: 'game_cat_Sport',
        icon: 'icon-game-cat5',
        alias: 'wap_kidsapp_sports'
    },
    {
        id: 8,
        name_i18n: 'category_title_puzzle',
        icon: 'icon-game-cat2',
        alias: 'wap_kidsapp_puzzle'
    }
];

export const avatarMapping = [
    'avatar1.png', //elephant
    'avatar2.png', //lion
    'avatar3.png', //chameleon
    'avatar4.png', //monkey
    'avatar5.png', //flamingo
    'avatar6.png', //koala
    'avatar7.png', //zebra
    'avatar8.png', //tiger
];

export const sliderSettings =  {
    centerMode: true,
    arrows: false,
    centerPadding: '60px',
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 200,
    swipeToSlide: true,
    infinite: false,
    initialSlide: 1,
    responsive: [
        {
            breakpoint: 1400,
            settings: {
                slidesToShow: 6,
                initialSlide: 2,
                centerPadding: '40px',
            }
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 5,
                initialSlide: 2,
                centerPadding: '40px',
            }
        },
        {
            breakpoint: 850,
            settings: {
                slidesToShow: 4,
                centerPadding: '40px',
            }
        },
        {
            breakpoint: 700,
            settings: {
                centerPadding: '40px',
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 480,
            settings: {
                centerPadding: '40px',
                slidesToShow: 1,
                initialSlide: 0,
            }
        }
    ]
};

export const ageRanges = [
    {
        selectorValue: '3-5'
    },
    {
        selectorValue: '6-7'
    },
    {
        selectorValue: '8-10'
    }
];
