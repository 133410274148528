import React, {Component, Fragment} from 'react';
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import styles from '../../ParentZone.module.css';
import TopBar from "../../../../components/Navigation/TopBar/TopBar";
import {text} from "../../../../utils/translator";
import logger from "../../../../utils/logger";


class Downloads extends Component {
    state = {
        downloadedGames: []
    };

    componentDidMount() {
        logger.debug('ManageGames.js componentDidMount',this.props,this.state);
        window.scrollTo(0, 0);
        let downloadedGames = JSON.parse(localStorage.getItem('downloadedGames'));
        if (downloadedGames) {
            this.setState({downloadedGames: downloadedGames});
        }
    };

    removeGameFromCache = (game) => {
        logger.debug('removeGameFromCache',game);
        let downloadedGames = JSON.parse(localStorage.getItem('downloadedGames'));
        if (downloadedGames) {
            downloadedGames.forEach((dlGame, index) => {
                if (dlGame.id === game.id) {
                    downloadedGames.splice(index, 1);
                }
            });
            this.setState({downloadedGames: downloadedGames});
            localStorage.setItem('downloadedGames', JSON.stringify(downloadedGames));
        }

    };


    render() {
        logger.debug('Downloads.js',this.state,this.props);

        let gameList = (
            this.state.downloadedGames.map(game => {
                return <div className={styles.SettingsLine} key={game.pId}>
                    <img src={game.icon.replace(/http/, 'https')} className={styles.AccessControlsGameImage}
                         alt={game.title}/>
                    <div className={styles.AccessControls}>{game.title}<br/>
                    <span style={{color:"gray", fontSize:'12px'}}>{(game.size/1000000).toFixed(2)}Mb</span>
                    </div>
                    <span className={'icons icon-remove'} style={{color: 'grey'}}
                          onClick={() => this.removeGameFromCache(game)}></span>
                </div>
            })
        );
        return (
            <Fragment>
                <TopBar pageTitle={text('downloads')} backToHandler={(backTo) => this.props.history.push(backTo)}
                        hasHomeButton={true} backTo={"/settings"}/>
                <div className={styles.SectionTitleWithImage}>
                    <span className={styles.AccessControlsImage + ' icons icon-game-cat4'} alt='stories'></span>
                    {text('downloads')}
                </div>
                {gameList}
            </Fragment>
        );
    };

}

const mapStateToProps = state => {
    return {
        updateProfileId: state.parentZone.updateProfileId,
        kidProfile: state.parentZone.kidProfile,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onProfileUpdate: (profile, updateProfileId) => dispatch(actions.updateKidProfile(profile, updateProfileId)),
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Downloads);