import React from 'react';
import styles from './Spinner.module.css';

const spinner = (props) => {

    const spinnerClass = () => {
        switch (props.type) {
            case 'BlueColor':
                return styles.LoaderBlue;
            case 'RedColor':
                return styles.LoaderRed;
            default:
                return styles.Loader
        }
    };

    return (
        <div className={spinnerClass()} style={props.style}></div>
    );
};

export default spinner;