import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from 'react-router-dom';

import {createStore, applyMiddleware, compose, combineReducers} from 'redux';
import {Provider} from 'react-redux';
import parentZoneReducer from './store/reducers/parentZone';
import kidZoneReducer from './store/reducers/kidZone';
import authReducer from './store/reducers/auth';
import subscriptionReducer from './store/reducers/subscription';
import registerReducer from './store/reducers/register';
import profileReducer from './store/reducers/profile';
import trackingReducer from './store/reducers/tracking';
import audioReducer from './store/reducers/audio';
import thunk from 'redux-thunk';
import WebFont from 'webfontloader';
import TagManager from 'react-gtm-module'
import ErrorBoundary from "./hoc/ErrorBoundary/ErrorBoundary";
import logger from "./utils/logger";
import {getCookie} from "./utils/cookies";

logger.debug('test debug');

const tagManagerArgs = {
    gtmId: 'GTM-NN72247'
};

parseInt(getCookie('eTgdpr')) === 7 && TagManager.initialize(tagManagerArgs);

WebFont.load({
    google: {
        families: ['Montserrat:600', 'Montserrat:500', 'sans-serif']
    }
});
window.addEventListener('gesturestart', function (event) {
    event.preventDefault();
});
window.addEventListener('touchmove', function (event) {
    event = event.originalEvent || event;
    if (event.scale  && event.scale !== 1) {
        event.preventDefault();
    }
}, {passive: false});

let composeEnhancers = compose;
if (process.env.NODE_ENV === 'development') {
    if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ !== undefined) {
        composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
    } else {
        logger.warn('Running development build without React DEV TOOL');
    }
}

const store = createStore(combineReducers(
    {
        parentZone: parentZoneReducer,
        kidZone: kidZoneReducer,
        auth: authReducer,
        register: registerReducer,
        subscription: subscriptionReducer,
        profile: profileReducer,
        tracking: trackingReducer,
        audio: audioReducer
    }
), composeEnhancers(
    applyMiddleware(thunk)
));

const app = (
    <ErrorBoundary><Provider store={store}>
        <BrowserRouter basename={''}>
            <App/>
        </BrowserRouter>
    </Provider>
    </ErrorBoundary>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.register();
