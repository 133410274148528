import React from 'react';

const checkbox = (props) => {
    let checkboxIcon = 'icon-checkbox-off';

    let style = {...props.style};


    switch (props.checkBoxStatus) {
        case 'checked':
            checkboxIcon = 'icon-checkbox-on';
            style = {...style, color: '#F1696B'};
            break;
        case 'halfchecked':
            checkboxIcon = 'icon-checkbox-partial';
            style = {...style, color: '#F1696B'};
            break;
        default:
            break;
    }

    return (<div onClick={() => props.onChangeHandler(props.elementId)} style={style}>
        <span className={'icons ' + checkboxIcon}></span>
    </div>);
};

export default checkbox;