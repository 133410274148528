import React, {Component, Fragment} from 'react';
import * as actions from "../../../../../store/actions";
import {connect} from "react-redux";
import styles from '../../../ParentZone.module.css';
import Checkbox from '../../../../../components/UI/Checkbox/Checkbox';
import TopBar from "../../../../../components/Navigation/TopBar/TopBar";
import {text} from "../../../../../utils/translator";
import logger from "../../../../../utils/logger";
import {filterContentList} from "../../../../../utils/kidZoneManager";
import noGameIcon from "../../../../../assets/images/placeholder-video.svg";

class ManageStories extends Component {
    state = {
        pageTitle: '',
    };


    componentDidMount() {
        logger.debug('ManageVideos.js componentDidMount',this.props,this.state);
        window.scrollTo(0, 0);
        let title = text('empty_content_access');
        if(this.props.kidProfile && this.props.kidProfile.name){
            title = text('kids_content_access', this.props.kidProfile.name);
        }
        this.props.getSection('audio_list');
        this.setState({pageTitle: title})
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.storyList && prevProps.storyList !== this.props.storyList) {
            this.props.storyList.sub_sections.forEach(subsection => {
                let section = this.props.subsections.find(section => section.identifier === subsection.identifier);

                if (section === undefined) {
                    this.props.getSection(subsection.identifier);
                }
            })
        }
    }

    storyAccessChange = (pId) => {
        let updatedBlockedStories = this.props.kidProfile.stories.blocked;
        let inList = false;
        if(updatedBlockedStories !== undefined){
            this.props.kidProfile.stories.blocked.forEach((gameId, index) => {
                if(gameId === pId){
                    inList = true;
                    updatedBlockedStories.splice(index, 1);
                }
            });
        } else {
            updatedBlockedStories = [];
        }

        if(!inList){
            updatedBlockedStories.push(pId);
        }

        let updatedProfile = {
            ...this.props.kidProfile,
            stories: {
                ...this.props.kidProfile.stories,
                blocked: updatedBlockedStories
            }
        };

        this.props.onProfileUpdate(updatedProfile, this.props.updateProfileId);
    };

    getCheckBoxStatus = (pId) => {
        if(this.props.kidProfile !== null && (!this.props.kidProfile.stories.blocked || this.props.kidProfile.stories.blocked.length === 0)){
            return "checked"
        }
        let status = "checked";
        this.props.kidProfile.stories.blocked.forEach(videoId => {
            if(videoId === pId){
                status = "unchecked"
            }
        });
        return status;
    };


    render() {
        logger.debug('ManageStories.js',this.state,this.props);

        let filteredSections = [];
        let uniqueIdentifiers = [];

        if(this.props.storyList){
            if(this.props.storyList.sub_sections.length > 0) {
                filteredSections = this.props.storyList.sub_sections.map( subsection => {
                    if(!uniqueIdentifiers.includes(subsection.identifier)) {
                        let titleStyle = null;

                        if(subsection.title.length > 30){
                            titleStyle = {lineHeight: '20px', marginTop: '10px'};
                        }
                        uniqueIdentifiers.push(subsection.identifier);
                        return <div className={styles.SettingsLine} key={subsection.identifier}>
                            <img src={subsection.icon ? subsection.icon.replace(/http/, 'https') : noGameIcon}
                                 className={styles.AccessControlsGameImage}
                                 alt={subsection.title}/>
                            <div className={styles.AccessControls} style={titleStyle}>{subsection.title}</div>
                            <Checkbox
                                checkBoxStatus={this.getCheckBoxStatus(subsection.identifier)}
                                onChangeHandler={(id) => this.storyAccessChange(id)}
                                elementId={subsection.identifier}
                            />
                        </div>
                    }
                });
            }
        }

        return (
            <Fragment>
                <TopBar pageTitle={this.state.pageTitle} backToHandler={(backTo) => this.props.history.push(backTo)} hasHomeButton={true} backTo={"/createKidProfile"}/>
                <div className={styles.SectionTitleWithImage} >
                    <span className={styles.AccessControlsImage +' icons icon-story'} alt='stories'></span>
                    {text('Stories')}</div>
                {filteredSections}
            </Fragment>
        );
    };

};

const mapStateToProps = state => {
    return {
        updateProfileId: state.parentZone.updateProfileId,
        kidProfile: state.parentZone.kidProfile,
        loading: state.kidZone.loading,
        error: state.kidZone.error,
        storyList: state.kidZone.storyList,
        subsections: state.kidZone.subsections,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onProfileUpdate: (profile, updateProfileId) => dispatch(actions.updateKidProfile(profile, updateProfileId)),
        onEditKidProfile: (profile, id) => dispatch(actions.updateKidProfile(profile, id)),
        getSection: (section) => dispatch(actions.getSection(section))
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(ManageStories);