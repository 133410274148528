import * as actionTypes from '../actions/actionTypes';
import {updateObject} from "../utility";

const initialState = {
    isSubscribed: false,
    loading: false,
    error: null,
    subInfo: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_SUBSCRIPTION_STATUS_STARTED: return updateObject(state, { loading: true });
        case actionTypes.GET_SUBSCRIPTION_STATUS_SUCCESS: return updateObject(state,{ loading: false, subInfo: action.subInfo, isSubscribed: action.isSubscribed });
        case actionTypes.GET_SUBSCRIPTION_STATUS_FAILED: return updateObject(state, { loading: false, error: action.error});

        case actionTypes.SUBSCRIPTION_SUBSCRIBE_STARTED: return updateObject(state, { loading: true });
        case actionTypes.SUBSCRIPTION_SUBSCRIBE_SUCCESS: return updateObject(state,{ loading: false, subInfo: action.subInfo, isSubscribed: true });
        case actionTypes.SUBSCRIPTION_SUBSCRIBE_FAILED: return updateObject(state, { loading: false, error: action.error});
        case actionTypes.GET_SUBSCRIPTION_CLEAR_ERRORS: return updateObject(state, { error: null });
        default: return state;
    }
};

export default reducer;