import React, {Component, Fragment} from 'react';
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import styles from './PinVerification.module.css';
import md5 from 'md5';
import {Redirect} from 'react-router-dom';
import {text} from "../../../utils/translator";
import SplashScreen from "../../../components/UI/SplashScreen/SplashScreen";
import {playErrorAudio} from "../../../utils/audio";
import ForgotPINModal from "../../../components/UI/Modal/ForgotPINModal";
import Modal from "../../../components/UI/Modal/Modal";

class PinVerification extends Component {
    state = {
        pin: '',
        forgotPinModal: false
    };

    pinForgotClickHandler = (e) => {
        if (e) e.preventDefault();
        this.setState({forgotPinModal: true});
    };

    pinButtonClickHandler = (event) => {
        if (this.state.pin.length < 4) {
            let newPin = this.state.pin + event.target.innerText;
            this.setState({pin: newPin});
            if (newPin.length === 4) {
                this.props.verifyPinCode('$md5$' + md5(newPin));
            }
        }
        /*
        if(!this.props.audioBackgroundPlaying){
            let background = getBackgroundAudio(3);
            this.props.audioStart(background);
        }
        */
    };
    deletePinHandler = () => {
        let newPin = this.state.pin.slice(0, this.state.pin.length - 1);
        if (newPin) {
            this.setState({pin: newPin});
        } else {
            this.setState({pin: ''});
        }
    };
    goBack = () => {
        this.props.history.goBack();
    };

    isPropsEqual = (newProps, oldProps) => {
        return newProps.isPinVerified === oldProps.isPinVerified && newProps.pinSaved === oldProps.pinSaved && newProps.isPinVerificationFailed === oldProps.isPinVerificationFailed && newProps.forgotPinModal === oldProps.forgotPinModal;
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !this.isPropsEqual(this.props, nextProps) || JSON.stringify(nextState) !== JSON.stringify(this.state);
    }

    render() {
        let redirect = null;
        if (this.props.isPinVerified || this.props.pinSaved) {
            redirect = <Redirect to="/kids"/>;
        }

        let errorValidationModal = null;
        if (this.props.isPinVerificationFailed) {
            playErrorAudio();
            errorValidationModal = (<Modal
                show={true}
                modalTitle={text('Wrong_PIN_code')}
                actionButonTitle={text('OK')}
                actionButtonHandler={() => { this.props.clearPinVerification(); this.setState({pin: ''});}}
                hasCancelButton={false}
            >{text('PIN_code_does_not_match')}</Modal>)

        }

        //@TODO why we need back link on PIN page?
        /*
        let backLink = ( <div className={styles.BackLink} onClick={this.goBack}>
            <span className={'icons icon-arrow-back'} alt='back'></span>
        </div>);

         */
        let backLink = (<div className={styles.BackLink}>
        </div>);


        let splashScreen = (<SplashScreen lockPortrait='true' message={text('orientation_switch_parent')}/>);
        let resetModal = this.state.forgotPinModal ? (
            <ForgotPINModal closed={() => this.setState({forgotPinModal: false})}/>) : '';
        return (
            <Fragment>
                {splashScreen}
                {backLink}
                {resetModal}
                {redirect}
                {errorValidationModal}
                <div className={styles.PageTitle}>{text('pin_enter_passcode')}</div>
                <div className={styles.Dots}>
                    <div className={this.state.pin.length > 0 ? styles.DotFilled : null}></div>
                    <div className={this.state.pin.length > 1 ? styles.DotFilled : null}></div>
                    <div className={this.state.pin.length > 2 ? styles.DotFilled : null}></div>
                    <div className={this.state.pin.length > 3 ? styles.DotFilled : null}></div>
                </div>
                <div className={styles.Keyboard}>
                    <div onClick={this.pinButtonClickHandler}>1</div>
                    <div onClick={this.pinButtonClickHandler}>2</div>
                    <div onClick={this.pinButtonClickHandler}>3</div>
                    <div onClick={this.pinButtonClickHandler}>4</div>
                    <div onClick={this.pinButtonClickHandler}>5</div>
                    <div onClick={this.pinButtonClickHandler}>6</div>
                    <div onClick={this.pinButtonClickHandler}>7</div>
                    <div onClick={this.pinButtonClickHandler}>8</div>
                    <div onClick={this.pinButtonClickHandler}>9</div>
                    <div onClick={this.pinButtonClickHandler}>0</div>
                </div>
                <div className={styles.Footer}>
                    <div onClick={this.pinForgotClickHandler} className={styles.ForgotPin}>{text('pin_forgot')}</div>
                    <div className={styles.DeleteButton} onClick={this.deletePinHandler}>{text('pin_delete')}</div>
                </div>
            </Fragment>
        );
    };

}

const mapStateToProps = state => {
    return {
        isPinVerified: state.parentZone.isPinVerified,
        pinSaved: state.register.pinSaved,
        isPinVerificationFailed: state.parentZone.isPinVerificationFailed,
        audioBackgroundPlaying: state.audio.playing
    }
};
const mapDispatchToProps = dispatch => {
    return {
        verifyPinCode: (pin) => dispatch(actions.verifyPinCode(pin)),
        clearPinVerification: () => dispatch(actions.clearPinVerification()),
        audioStart: (audio) => dispatch(actions.audioStart(audio)),
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(PinVerification);