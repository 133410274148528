import React, {Component} from 'react';
import Error from "../../containers/Error/Error";
import logger from "../../utils/logger";



class ErrorBoundary extends Component {

    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }


    static getDerivedStateFromError(error) {
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        console.log('ErrorBoundary', error.stack);
        logger.error({location:"ErrorBoundary",error: error.toString(),stack: error.stack});
    }

    render() {
        if (this.state.hasError) {
            return (
                <main style={{
                    margin: '0',
                    height: '100%',
                    position: 'absolute',
                    width: '100%',
                    minHeight: '320px'
                }}>
                    <Error/>
                </main>
            );
        }
        return (this.props.children);
    }

}

export default ErrorBoundary;