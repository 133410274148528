import * as actionTypes from '../actions/actionTypes';
import {updateObject} from "../utility";

const initialState = {
    access_token: null,
    ecomm_id: null,
    error: null,
    loading: false,
    authRedirectPath: '/',
    hasAccount: false,
    isAuthenticated: false,
    termsAccepted: false,
};
const authSuccess = (state, action) => {
    return updateObject(state, {
        access_token: action.access_token,
        ecomm_id: action.ecomm_id,
        error: null,
        loading: false,
        hasAccount: action.hasAccount,
        isAuthenticated: true
    })
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START: return updateObject(state, { loading: true });
        case actionTypes.AUTH_FAILED: return updateObject(state,{ loading: false, error: action.error });
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_LOGOUT: return updateObject(state, { token: null, userId: null, isAuthenticated: false });
        case actionTypes.TERMS_ACCEPTED: return updateObject(state, { termsAccepted: true });
        case actionTypes.SET_AUTH_REDIRECT_PATH: return updateObject(state, { authRedirectPath: action.path });
        default: return state;
    }
};

export default reducer;