import * as actionTypes from '../actions/actionTypes';
import {updateObject} from "../utility";

const initialState = {
    playing: false,
    paused: false,
    source: null
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUDIO_PLAY:
            return updateObject(state, {playing: true, paused: false, source: action.source});
        case actionTypes.AUDIO_PAUSE:
            return updateObject(state, {playing: false, paused: true});
        case actionTypes.AUDIO_STOP:
            return updateObject(state, {playing: false, paused: false, source: null});
        case actionTypes.AUDIO_PLAY_FAILED:
        case actionTypes.AUDIO_PAUSE_FAILED:
        default:
            return state;
    }
};


export default reducer;