import axios from 'axios';

import {CLIENT_ID} from "../config";

class Logger {

     getFormattedDate = () => {
        let date = new Date();
         let str = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " +  date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

        return str;
    };

    argumentsToString = (...message) => {
        let args = Array.prototype.slice.call(...message);
        for (let k in args) {
            if (typeof args[k] === "object") {
                try {
                    args[k] = JSON.stringify(args[k]);
                } catch (e) {
                    args[k] = 'circular reference';
                }
            } else {
                args[k] = args[k];
            }
        }
        return args.join(" ");
    };


    debug = (...message) => {
        if (process.env.NODE_ENV === 'development') {
            console.log(...message);
        }
    };

    error = (...message) => {

        console.error(...message);

        let storageErrors = localStorage.getItem('pendingErrors');
        let pendingErrors = storageErrors != null ? JSON.parse(storageErrors) : [];

        pendingErrors.push({
            environment: process.env.NODE_ENV,
            message: this.argumentsToString(message),
            client: CLIENT_ID,
            credentials: 'ecomm:' + localStorage.getItem('ecomm_id'),
            timestamp: this.getFormattedDate()
        });

        localStorage.setItem('pendingErrors', JSON.stringify(pendingErrors));

        axios.post('https://iap.gameloft.com/beta/kids/error.php', pendingErrors).then(response => {
            if (response.status === 200) {
                pendingErrors = [];
                localStorage.setItem('pendingErrors', JSON.stringify([]));
            }
        }).catch(error => {

        });


    };

    warn = (...message) => {
        console.warn(...message);
    };
}

let logger = new Logger();

export default logger;
