import React, {Component} from 'react';
import styles from './SplashScreen.module.css';

class SplashScreen extends Component {

    PORTRAIT = 'portrait';
    LANDSCAPE = 'landscape';
    listener = null;

    constructor(props) {
        super(props);
        this.state = {
            orientation: this.detectOrientation()
        }
    }

    orientationChangeHandler = () => {
        if (this._isMounted) {
            this.toggleOrientation();
            setTimeout(() => {
                if (this.state.orientation !== this.detectOrientation()) {
                    this.toggleOrientation();
                }
            }, 1000);
        }
    };

    toggleOrientation = () => {
        this.setState(prevState => {
            return {orientation: prevState.orientation === this.PORTRAIT ? this.LANDSCAPE : this.PORTRAIT}
        });
    };

    detectOrientation = () => {
        return window.innerHeight > window.innerWidth ? this.PORTRAIT : this.LANDSCAPE;
    };

    componentDidMount() {
        this._isMounted = true;
        this.listener = window.addEventListener('orientationchange', this.orientationChangeHandler);
    }

    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener('orientationchange', this.listener);
    }

    render() {
        let classNamesContainer = [styles.SplashScreen];
        let iconInlineStyle = {fontSize: '100px', marginBottom: '15px'};

        if (this.props.lockLandscape && this.state.orientation === this.PORTRAIT) {
            classNamesContainer.push(styles.Portrait);
            iconInlineStyle.transform = 'rotate(90deg)';

        }
        if (this.props.lockPortrait && this.state.orientation === this.LANDSCAPE) {
            classNamesContainer.push(styles.Landscape);
        }
        return (<div className={classNamesContainer.join(' ')}>
            <div className={styles.SplashScreenContent}>
                <span className={'icons icon-orientation'} style={iconInlineStyle}></span>
                <div>{this.props.message}</div>
            </div>
        </div>);
    }
}

export default SplashScreen;