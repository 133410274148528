import * as actionTypes from '../actions/actionTypes';
import {updateObject} from "../utility";

const initialState = {
    loading: false,
    error: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SEND_TRACKING_STARTED: return updateObject(state, { loading: true });
        case actionTypes.SEND_TRACKING_SUCCESS: return updateObject(state,{ loading: false, error: false });
        case actionTypes.SEND_TRACKING_FAILED: return updateObject(state, { loading: false, error: true});

        default: return state;
    }
};

export default reducer;