export {
    saveKidProfile,
    updateKidProfile,
    verifyPinCode,
    removeKidProfile,
    profileEditCancel,
    clearPinVerification,
    startEditKidProfile,
    startCreatingKidProfile,
    setModalsModeNewUser,
    setModalsModeDefault,
    setModalsModeNone,
    skipTutorial
} from './parentZone';
export {
    auth,
    authlogout,
    authDone,
    authCheckState,
    setTermsWasAccepted,
} from './auth';
export {
    register,
    createPin,
    registerCheckState,
    clearRegError,
    resetRegisterState
} from './register';
export {
    checkSubscriptionStatus,
    subscribeUser,
    clearSubError,
} from './subscription';
export {
    changePassword,
    changePinCode,
    updateProfile,
    clearProfileError,
    loadProfileInfo,
    endTutorial
} from './profile';
export {
    kidZoneInit,
    getGames,
    getVideos,
    getVideoSrc,
    getStories,
    getStorySrc,
    getSection,
    refreshKidProfile,
    getPlayLink,
    flashMessageReset,
    flashMessageShown
} from './kidZone';
export {
    sendTracking,
} from './tracking';

export{
    audioStart,
    audioPause,
    audioResume,
    audioStop
 }from'./audio'