

const avatarSelectedSoundsAvailable = [
    'sfx_animal_elephant.mp3',
    'sfx_animal_lion.mp3',
    'sfx_animal_chameleon.mp3',
    'sfx_animal_monkey_01.mp3',
    'sfx_animal_flamingo.mp3',
    'sfx_animal_koala.mp3',
    'sfx_animal_zebra.mp3',
    'sfx_animal_tiger.mp3',
];

export const playAvatarAudio = (avatarId) => {
    new Audio(require('../assets/sounds/avatar/'+ avatarSelectedSoundsAvailable[avatarId -1])).play();
};

export const getEnterKidsZoneAudio = () => {
    return new Audio(require('../assets/sounds/sfx_ui_open_kid_area.mp3'));
};

export const getExitKidsZoneAudio = () => {
    return new Audio(require('../assets/sounds/sfx_ui_close_kid_area.mp3'));
};

export const getTabSwitchAudio = () => {
    return new Audio(require('../assets/sounds/sfx_ui_switch_tab.mp3'));
};

export const playPopUpShownAudio = () => {
    new Audio(require('../assets/sounds/sfx_ui_pop_up_normal.mp3')).play();
};

export const playErrorAudio = () => {
    new Audio(require('../assets/sounds/sfx_ui_pop_up_error.mp3')).play();
};

export const playTimeIsUpAudio = () => {
    new Audio(require('../assets/sounds/sfx_ui_pop_up_times_up.mp3')).play();
};

export const getBackgroundAudio = (id) => {
    let audio = new Audio(require('../assets/sounds/background/sfx_amb_fades_0'+id+'.mp3'));
    audio.loop = true;
    return audio;
};

