import * as actionTypes from './actionTypes';
import * as axiosHelper from "./axiosHelper";
import {skipTutorial} from "./parentZone";
import {text} from "../../utils/translator";
import logger from "../../utils/logger";


export const updateProfileStart = () => {
    return {
        type: actionTypes.UPDATE_PROFILE_START
    }
};
export const updateProfileSuccess = (dataToUpdate) => {
    logger.debug('updateProfileSuccess',dataToUpdate);
    return {
        type: actionTypes.UPDATE_PROFILE_SUCCESS,
        dataToUpdate: dataToUpdate
    }
};
export const getProfileFailed = (error) => {
    return {
        type: actionTypes.GET_PROFILE_FAILED,
        error: error
    }
};
export const getProfileStart = () => {
    return {
        type: actionTypes.GET_PROFILE_START
    }
};
export const getProfileSuccess = (profileInfo) => {
    logger.debug('getProfileSuccess',profileInfo);
    return {
        type: actionTypes.GET_PROFILE_SUCCESS,
        profileInfo: profileInfo
    }
};
export const updateProfileFailed = (error) => {
    return {
        type: actionTypes.UPDATE_PROFILE_FAILED,
        error: error
    }
};
export const clearProfileError = () => {
    return {
        type: actionTypes.UPDATE_PROFILE_CLEAR_ERROR,
    }
};
export const updateProfile = (fieldName, fieldValue) => {
    logger.debug('updateProfile',fieldName,fieldValue);
    return dispatch => {
        dispatch(updateProfileStart());
        let dataToUpdate = {};
        dataToUpdate[fieldName] = fieldValue;
        axiosHelper.updateMyProfile(dataToUpdate).then(response => {
            if (response === "OK") {
                dispatch(updateProfileSuccess(dataToUpdate));
            } else {
                dispatch(updateProfileFailed('Something went wrong'));
            }
        }).catch(error => {
            dispatch(updateProfileFailed(error));
        });
    }
};
export const changePassword = (oldPassword, newPassword) => {
    return dispatch => {
        dispatch(updateProfileStart());
        axiosHelper.getMyProfile().then(profileInfo => {
            if (profileInfo.password === oldPassword) {
                dispatch(updateProfile('password', newPassword));
            } else {
                dispatch(updateProfileFailed('Old password is not correct'));
            }
        }).catch(error => {
            dispatch(updateProfileFailed(error));
        });
    }
};

export const changePinCode = (oldPassword, newPassword) => {
    return dispatch => {
        dispatch(updateProfileStart());
        axiosHelper.getMyProfile().then(profileInfo => {
            if (profileInfo.parent.pin === oldPassword) {
                let dataToUpdate = {
                    parent: {pin: newPassword},
                };
                axiosHelper.updateMyProfile(dataToUpdate).then(response => {
                    if (response === "OK") {
                        return dispatch(updateProfileSuccess(dataToUpdate));
                    } else {
                        return dispatch(updateProfileFailed(text('reset_pin_error_default')));
                    }
                }).catch(error => {
                    if (error.response && error.response.status) {
                        if (error.response.status === 401) {
                            return dispatch(updateProfileFailed(text('reset_pin_error_401')));
                        }
                    }
                    return dispatch(updateProfileFailed(text('reset_pin_error_default')));
                })
            } else {
                return dispatch(updateProfileFailed(text('PIN_code_is_incorrect')));
            }
        }).catch(error => {
            logger.debug('changePinCode get profile error',error);
            return dispatch(updateProfileFailed(text('reset_pin_error_default')));
        });
    }
};

export const loadProfileInfo = () => {
    return dispatch => {
        dispatch(getProfileStart());
        axiosHelper.getMyProfile().then(profileInfo => {
            if (profileInfo) {
                dispatch(getProfileSuccess(profileInfo));
            } else {
                dispatch(getProfileFailed('Something went wrong'));
            }
        }).catch(error => {
            dispatch(getProfileFailed(error));
        });
    };
};

export const endTutorial = () => {
    logger.debug('endTutorial dispatch');
    return dispatch => {
        dispatch(updateProfileStart());
        axiosHelper.getMyProfile().then(profileInfo => {
            if (!profileInfo.isTutorialEnded) {
                dispatch(updateProfile('isTutorialEnded', true));
                dispatch(skipTutorial());
            } else {
                dispatch(updateProfileFailed('tutorial already ended'));
            }
            return {
                type: actionTypes.UPDATE_PROFILE_CLEAR_ERROR,
            }
        }).catch(error => {
            dispatch(updateProfileFailed(error));
        });
    };
};