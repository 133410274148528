import * as actionTypes from './actionTypes';


export const audioStart = (audio) => {
    return dispatch => {
        dispatch(audioPause());
        if (audio && audio.paused) {
            audio.play();
            dispatch({type: actionTypes.AUDIO_PLAY, source: audio});
        } else {
            dispatch({type: actionTypes.AUDIO_PLAY_FAILED});
        }

    };
};

export const audioPause = () => {
    return (dispatch, getState) => {
        const state = getState();
        if (state.audio.source && !state.audio.source.paused) {
            state.audio.source.pause();
            dispatch({type: actionTypes.AUDIO_PAUSE});
        } else {
            dispatch({type: actionTypes.AUDIO_PAUSE_FAILED});
        }

    };
};

export const audioResume = () => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(audioStart(state.audio.source));
    };
};

export const audioStop = () => {

    return dispatch => {
        dispatch(audioPause());
        dispatch({type: actionTypes.AUDIO_STOP});
    };

};


