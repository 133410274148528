import React, {Component} from 'react';
import styles from './SplashScreenRed.module.css';
import appIcon from '../../../assets/images/splash-screen-logo.png';

class SplashScreenRed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: true
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({show: false});
        }, 1000);
    }

    render() {
        return this.state.show ? <div className={styles.Screen}>
            <img className={styles.ScreenLogo} src={appIcon} />
        </div> : null;
    }
}

export default SplashScreenRed;