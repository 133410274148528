import {ageRanges} from "../config";
import logger from "./logger";

const mediaTypes = {game: 1, video: 2, stories: 3, '1': 1, '2': 2, '3': 3, 'app': 0};

export const updateContentUsage = (section, props, seconds, product_id, isLaunched) => {
    if (product_id === undefined) {
        product_id = 0;
    }
    if (parseInt(seconds) > 60) {
        seconds = 60;
    } else {
        seconds = parseInt(seconds);
    }
    logger.debug('updateContentUsage', section, seconds, product_id, isLaunched);
    let mediaType = mediaTypes[section];
    let contentUsage = props.profileInfo.kid_usage ? props.profileInfo.kid_usage : {};
    let currentKidUsage = contentUsage[props.kidProfile.id] ? contentUsage[props.kidProfile.id] : {};
    let date = new Date();
    let currentDate = date.getFullYear() + ('0' + (date.getMonth() + 1)).slice(-2) + ('0' + date.getDate()).slice(-2);
    let usageLabel = props.kidProfile.id + "_" + currentDate + "_" + mediaType + "_" + product_id;

    if (currentKidUsage[usageLabel]) {

        //fix for old profiles
        let launches = currentKidUsage[usageLabel].launches === undefined ? 1 : currentKidUsage[usageLabel].launches;

        if (isLaunched) {
            launches = launches + 1;
        }

        currentKidUsage[usageLabel] = {
            ...currentKidUsage[usageLabel],
            spent_time_playing: currentKidUsage[usageLabel].spent_time_playing + seconds,
            launches: launches
        };
    } else {
        currentKidUsage[usageLabel] = {
            kid_id: props.kidProfile.id,
            media_type: mediaType,
            playing_date: date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2),
            product_id: product_id,
            spent_time_playing: seconds,
            launches: 1
        };
    }
    contentUsage[props.kidProfile.id] = currentKidUsage;
    props.updateContentUsage({...contentUsage});
};


export const checkIsTimeToPlayAllowed = (props) => {
    let date = new Date();
    let minutesStart = props.kidProfile.weekDay_allowTimeInDay_Start;
    let minutesEnd = props.kidProfile.weekDay_allowTimeInDay_End;
    if (date.getDay() === 0 || date.getDay() === 6) {
        minutesStart = props.kidProfile.weekendDay_allowTimeInDay_Start;
        minutesEnd = props.kidProfile.weekendDay_allowTimeInDay_End;
    }
    let minutesFromMidnight = date.getHours() * 60 + date.getMinutes();
    logger.debug('checkIsTimeToPlayAllowed', minutesFromMidnight, minutesStart, minutesEnd);

    if (minutesFromMidnight > minutesStart && minutesFromMidnight < minutesEnd) {
        return true;
    }
    return false;
};

export const checkIfKidStillCanPlay = (props) => {
    let playedTime = getSecondsPlayedTodayTotal(props);
    let availableTime = getSecondsAvailableTodayTotal(props);
    logger.debug('checkIfKidStillCanPlay', 'playedTime= ' + playedTime, 'availableTime= ' + availableTime);
    return availableTime > playedTime;
};

export const getTodayPlayTimeEnd = (props) => {
    let playedTime = getSecondsPlayedTodayTotal(props);
    let availableTime = getSecondsAvailableTodayTotal(props);
    let estimatedEndDate = new Date(Date.now() + ((availableTime - playedTime) * 1000));
    logger.debug('getTodayPlayTimeEnd', 'playedTime= ' + playedTime, 'availableTime= ' + availableTime, availableTime - playedTime, estimatedEndDate.toISOString());
    return estimatedEndDate;
};

export const getSecondsAvailableTodayTotal = (props) => {
    let date = new Date();
    let available = (date.getDay() === 0 || date.getDay() === 6) ? props.kidProfile.weekendDay_maxDurationInDay : props.kidProfile.weekDay_maxDurationInDay;
    return available * 60;
};


const getSecondsPlayedTodayTotal = (props) => {
    let totalTime = 1;
    let date = new Date();
    let contentUsage = {};
    if (props.profileInfo.kid_usage !== undefined && props.profileInfo.kid_usage[props.kidProfile.id] !== undefined) {
        contentUsage = props.profileInfo.kid_usage[props.kidProfile.id];
    }
    let contentUsageArray = [];

    for (let key in contentUsage) {
        contentUsageArray.push({...contentUsage[key]});
    }
    let playing_date = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
    contentUsageArray.filter(item => item.playing_date === playing_date).forEach(filtered => {
        totalTime = totalTime + filtered.spent_time_playing;
    });
    return totalTime;
};

export const favoriteTogglerOld = (type, item, props) => {
    if (type === 'game'){
        type = 'games';
    } else if (type === 'video'){
        type = 'videos';
    }
    let favorites = props.kidProfile[type] && props.kidProfile[type].favorites ? props.kidProfile[type].favorites : [];
    if (!favorites.includes(item.id)) {
        favorites.push(item.id);
    } else {
        favorites.splice(favorites.indexOf(item.id), 1);
    }
    let sectionData = {};
    sectionData[type] = {
        ...props.kidProfile[type],
        favorites: favorites
    };
    props.updateKidProfile({
        ...props.kidProfile,
        ...sectionData
    }, props.profileInfo.kids, props.kidProfile.id);
};

export const favoriteToggler = (type, item, props) => {
    let mediaType = mediaTypes[type];

    let favorites = props.kidProfile.favorites ? props.kidProfile.favorites : [];
    console.log(mediaType, favorites, props.kidProfile);
    if(props.kidProfile.games.favorites && props.kidProfile.games.favorites.length > 0){
        props.kidProfile.games.favorites.forEach(fav => {
            favorites.push({type: 1, id: fav});
        });
    }
    if(props.kidProfile.videos.favorites && props.kidProfile.videos.favorites.length > 0){
        props.kidProfile.videos.favorites.forEach(fav => {
            favorites.push({type: 2, id: fav});
        });
    }
    if(props.kidProfile.stories.favorites && props.kidProfile.stories.favorites.length > 0){
        props.kidProfile.stories.favorites.forEach(fav => {
            favorites.push({type: 3, id: fav});
        });
    }
    if (favorites.filter((fav) => fav.id == item.id && fav.type === mediaType).length === 0) {
        favorites.push({type: mediaType, id: item.id});
    } else {
        favorites.forEach((fav, index) => {
            if(fav.id == item.id){
                favorites.splice(index, 1);
            }
        });
    }
    props.updateKidProfile({
        ...props.kidProfile,
        games: {
            ...props.kidProfile.games,
            favorites: [],
        },
        videos: {
            ...props.kidProfile.videos,
            favorites: [],
        },
        stories: {
            ...props.kidProfile.stories,
            favorites: [],
        },
        favorites: favorites
    }, props.profileInfo.kids, props.kidProfile.id);
};

export const formatTotalPlayedTimeByType = (type, state) => {
    let durationInSeconds = 0;
    let date = new Date();
    let playing_date = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);

    if (state.activeTodayTab) {
        state.contentUsageArray.filter(item => item.media_type === mediaTypes[type] && item.playing_date === playing_date)
            .forEach(filtered => {
                durationInSeconds = durationInSeconds + filtered.spent_time_playing;
            });
    } else {
        let firstDate = new Date(date.getTime() - (7 * 24 * 60 * 60 * 1000));
        let playing_date = firstDate.getFullYear() + '-' + ('0' + (firstDate.getMonth() + 1)).slice(-2) + '-' + ('0' + firstDate.getDate()).slice(-2);
        state.contentUsageArray.filter(item => item.media_type === mediaTypes[type] && new Date(item.playing_date).getTime() >= new Date(playing_date).getTime())
            .forEach(filtered => {
                durationInSeconds = durationInSeconds + filtered.spent_time_playing;
            });
    }

    if (durationInSeconds > 0) {
        let hours = Math.floor(durationInSeconds / (60 * 60));
        let minutes = ((durationInSeconds - hours * 60 * 60) / 60).toFixed(0);
        if (hours <= 0) {
            if(minutes > 0) {
                return minutes + 'm';
            } else {
                return durationInSeconds + 'sec';
            }
        } else {
            return hours + 'h ' + minutes + 'm';
        }

    } else {
        return "0m";
    }

};

export const formatUsedTimeDuration = (state) => {
    let possibleHours = Math.floor(state.totalAvailableTime / (60 * 60));
    let possibleMinutes = parseInt(((state.totalAvailableTime - possibleHours * 60 * 60) / 60).toFixed(0));
    logger.debug(possibleHours, possibleMinutes);
    if (state.totalTime > 0) {
        let hours = Math.floor(state.totalTime / (60 * 60));
        let minutes = ((state.totalTime - hours * 60 * 60) / 60).toFixed(0);
        if (hours <= 0) {
            if (possibleHours <= 0) {
                return minutes + 'm/ ' + possibleMinutes + 'm';
            } else {
                return minutes + 'm/ ' + possibleHours + 'h ' + possibleMinutes + 'm';
            }

        } else {
            return hours + 'h ' + minutes + 'm/ ' + possibleHours + 'h ' + possibleMinutes + 'm';
        }

    } else {
        return '0m/ ' + possibleHours + 'h ' + possibleMinutes + 'm';
    }

};

export const calculateProgressBarWidthByType = (type, state) => {
    let durationInSeconds = 0;
    let date = new Date();
    let playing_date = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
    if (state.activeTodayTab) {
        state.contentUsageArray.filter(item => item.media_type === mediaTypes[type] && item.playing_date === playing_date)
            .forEach(filtered => {
                durationInSeconds = durationInSeconds + filtered.spent_time_playing;
            });
    }

    if (durationInSeconds > 0) {
        return (durationInSeconds * 100 / state.totalTime).toFixed(0) + '%';
    } else {
        return "1px";
    }

};

export const calculateUsedTimeProgressBarWidth = (state) => {
    if (state.totalTime > state.totalAvailableTime) {
        return '100%';
    }
    if (state.totalTime > 0) {
        return parseInt((state.totalTime * 100 / state.totalAvailableTime).toFixed(0)) + '%';
    } else {
        return "1px";
    }

};

export const sendTrackingData = (trackingData, props) => {
    props.sendTrackingData(trackingData);
    if (navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage({'tracking_data': trackingData});
    }
};
export const convertDateForIos = (date) => {
    let arr = date.split(/[- :]/);
    date = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
    return date;
};

export const updateWatchedTime = (props, secondsPlayed, videoId, duration) => {
    let videoTime = props.profileInfo.video_time ? props.profileInfo.video_time : {};
    let seconds = isNaN(secondsPlayed) ? 0 : secondsPlayed;
    if (seconds > 0) {
        logger.debug('updateWatchedTime triggered', seconds, videoId);
        let date = new Date();
        let playing_date = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
        if (videoTime[props.kidProfile.id] === undefined) {
            videoTime[props.kidProfile.id] = {};
        }
        videoTime[props.kidProfile.id][videoId] = {last_second: seconds, date: playing_date, duration: duration};
        props.updateWatchedTime(videoTime);
    }
};
export const updateAudioListenTime = (props, secondsPlayed, storyId, duration) => {
    let audioTime = props.profileInfo.audio_time ? props.profileInfo.audio_time : {};
    let seconds = isNaN(secondsPlayed) ? 0 : secondsPlayed;
    if (seconds > 0) {
        logger.debug('updateAudioListenTime triggered', seconds, storyId);
        let date = new Date();
        let playing_date = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
        if (audioTime[props.kidProfile.id] === undefined) {
            audioTime[props.kidProfile.id] = {};
        }
        audioTime[props.kidProfile.id][storyId] = {last_second: seconds, date: playing_date, duration: duration};
        props.updateWatchedTime(audioTime);
    }
};

export const filterContentList = (contentList, profile, limit, type, category, filterBlocked = true) => {
    let contentListClean = [];
    if (contentList != null && contentList.length > 0) {
        contentListClean = contentList.filter(content => content != null);
    }

    let filtered = [];
    let contentAssets = profile[type];

    if(!contentAssets?.allowed){
        return [];
    }
    let allowed = contentListClean.filter(item => {

        /*if (type === 'games' && item.type !== 'html5' || type !== 'games' && item.type && item.type === 'html5') {
            filtered.push({
                id: item.id,
                type: item.type,
                age_rating: item.age_rating,
                categories: item.categories,
                reason: 'blocked content with wrong type'
            });
            return false;
        }   */


        let blockedCategories = contentAssets && contentAssets.blocked_categories ? contentAssets.blocked_categories : [];
        let blockedIds = contentAssets.blocked ? contentAssets.blocked : [];
        //filter blocked games
        if (filterBlocked && blockedIds.includes(item.id)) {
            filtered.push({
                id: item.id,
                type: item.type,
                age_rating: item.age_rating,
                categories: item.categories,
                reason: 'blocked content id'
            });
            return false;
        }

        //filter games in blocked categories
        if (item.categories && item.categories.length > 0) {
            for (let i = 0; i < item.categories.length; i++) {
                if (blockedCategories.includes(item.categories[i])) {

                    filtered.push({
                        id: item.id,
                        type: item.type,
                        age_rating: item.age_rating,
                        categories: item.categories,
                        reason: 'blocked category ' + item.categories[i]
                    });

                    return false
                }
            }
        }

        let rangeAge = ageRanges.find(range => parseInt(range.selectorValue.split("-", 2)[0]) === profile.age);
        if (rangeAge !== undefined) {
            let rangeAgeMin = parseInt(rangeAge.selectorValue.split("-", 2)[0]);
            let rangeAgeMax = parseInt(rangeAge.selectorValue.split("-", 2)[1]);
            let ageRating = item.age_rating ? parseInt(item.age_rating) : NaN;
            //filter games by age rating
            if (ageRating > rangeAgeMax) {
                filtered.push({
                    id: item.id,
                    type: item.type,
                    age_rating: item.age_rating,
                    categories: item.categories,
                    reason: 'content age rating  > ' + rangeAgeMax + ' or < ' + rangeAgeMin
                });
                return false;
            }
        }
        //filter games for current active category
        if (category && category !== 'all' && !item.categories.includes(category)) {

            filtered.push({
                id: item.id,
                type: item.type,
                age_rating: item.age_rating,
                categories: item.categories,
                reason: 'content not in required category ' + category
            });

            return false;
        }
        return true;
    });

    return limit >= 0 ? allowed.slice(0, allowed.length >= limit ? limit : allowed.length) : allowed;
};


/**
 *
 * @param ids array
 * @param contents array of objects
 * @returns {*}
 */
export const getContentListFromIds = (ids, contents) => {
    if (!ids) {
        return [];
    }
    return ids.map(id => {
        return contents.find(content => {
            let contentId = parseInt(content.id);
            let searchId = parseInt(id);
            if (isNaN(contentId) || isNaN(searchId)) {
                return false
            }
            return contentId === searchId;
        })
    }).filter(item => item != null);
};

export const getFavoritesListOld = (kidProfile, gameList, videoList) => {
    let favorites = [];
    let games = filterContentList(getContentListFromIds(kidProfile.games.favorites, gameList), kidProfile, -1, 'games');
    favorites = [...favorites, ...games];
    let videos = filterContentList(getContentListFromIds(kidProfile.videos.favorites, videoList), kidProfile, -1, 'videos');
    favorites = [...favorites, ...videos];
    return favorites;
};

export const getFavoritesList = (kidProfile, gameList, videoList, storyList = []) => {

    let favorites = [];
    let gameFavorites = kidProfile.favorites ? kidProfile.favorites.filter(fav => fav.type == 1).map(favItem => favItem.id)
        : kidProfile.games.favorites ? kidProfile.games.favorites : [];
    let videoFavorites = kidProfile.favorites ? kidProfile.favorites.filter(fav => fav.type == 2).map(favItem => favItem.id)
        : kidProfile.videos.favorites ? kidProfile.videos.favorites : [];
    let storyFavorites = kidProfile.favorites ? kidProfile.favorites.filter(fav => fav.type == 3).map(favItem => favItem.id)
        : kidProfile.stories.favorites ? kidProfile.stories.favorites : [];

    let games = filterContentList(getContentListFromIds(gameFavorites, gameList), kidProfile, -1, 'games');
    let videos = filterContentList(getContentListFromIds(videoFavorites, videoList), kidProfile, -1, 'videos');
    let stories = filterContentList(getContentListFromIds(storyFavorites, storyList), kidProfile, -1, 'stories');

    if(kidProfile.favorites){
        kidProfile.favorites.forEach(fav => {
            if(games.filter(game => fav.id === game.id).length){
                favorites.push({...games.filter(game => fav.id === game.id)[0], type: 1});
            }
            if(videos.filter(videos => fav.id === videos.id).length){
                favorites.push({...videos.filter(videos => fav.id === videos.id)[0], type: 2});
            }
            if(stories.filter(story => fav.id === story.id).length){
                favorites.push({...stories.filter(story => fav.id === story.id)[0], type: 3});
            }
        });
    } else {
        favorites.push(...games, ...videos, ...stories);
    }

    return favorites;
};

export const uuid = () => {
    const hashTable = [
        'a',
        'b',
        'c',
        'd',
        'e',
        'f',
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9'
    ];
    let uuid = [];
    for (let i = 0; i < 36; i++) {
        if (i === 8 || i === 13 || i === 18 || i === 23) {
            uuid[i] = '-'
        } else {
            uuid[i] = hashTable[Math.abs(Math.floor(Math.random() * hashTable.length - 1))]
        }
    }
    return uuid.join('')
};

export const imageExists = (src) => {
    return new Promise((resolve, reject) => {
        let img = new Image()
        img.onload = () => resolve(img.height)
        img.onerror = reject
        img.src = src
    })
}