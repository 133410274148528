import * as actionTypes from './actionTypes';
import * as axiosHelper from "./axiosHelper";
import {updateProfileFailed} from "./profile";
import logger from "../../utils/logger";

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    }
};

export const authSuccess = (authData) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        access_token: authData.access_token,
        ecomm_id: authData.ecomm_id,
        hasAccount: authData.hasAccount
    }
};
export const authFailed = (error) => {
    return {
        type: actionTypes.AUTH_FAILED,
        error: error
    }
};
export const logout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('ecomm_id');
    localStorage.removeItem('profileInfo');
    localStorage.removeItem('onlineProfileIsOutDated');
    localStorage.removeItem('hasAccount');
    localStorage.removeItem('authExpirationDate');

    return {
        type: actionTypes.AUTH_LOGOUT,
    }
};
export const checkAuthTimeOut = (expiresIn) => {
    return dispatch => {
        logger.debug('checkAuthTimeOut in : ',expiresIn);
        setTimeout(() => {
            dispatch(logout());
        }, expiresIn * 1000);
    }
};

export const auth = () => {
    return dispatch => {
        dispatch(authStart());
        axiosHelper.getAuthProfile().then(response => {
            if (response.login_url) {
                if(window.location.search){
                    if(response.login_url.includes('?')){
                        window.location.href = response.login_url + window.location.search.replace('?', '&');
                    } else {
                        window.location.href = response.login_url + window.location.search;
                    }
                } else {
                    window.location.href = response.login_url;
                }
            }
        }).catch(error => {
            logger.debug(error);
            dispatch(authFailed());
        });
    };
};

export const authlogout = () => {
    return dispatch => {
        dispatch(logout());
        axiosHelper.getAuthProfile().then(response => {
            if (response.logout_url) {
                window.location.href = response.logout_url;
            }
            logger.debug(response);
        }).catch(error => {
            logger.debug(error);
        });
    };
};
export const authDone = (token, ecommId, expiresIn, clientId,price) => {
    return dispatch => {
        dispatch(authStart());
        localStorage.setItem('auth_token', token);
        localStorage.setItem('ecomm_id', ecommId);
        localStorage.setItem('client_id', clientId);
        localStorage.setItem('authExpirationDate', new Date(new Date().getTime() + parseInt(expiresIn) * 1000).toGMTString());
        localStorage.setItem('price', price);
        axiosHelper.getMyProfile().then(profileInfo => {
            let hasAccount = 0;
            if (profileInfo !== '') {
                localStorage.setItem('hasAccount', 1);
                hasAccount = 1;
            }
            dispatch(authSuccess({
                access_token: token,
                ecomm_id: ecommId,
                hasAccount: hasAccount
            }));
        }).catch(error => {
            dispatch(updateProfileFailed(error));
        });
    };
};
export const setTermsWasAccepted = () => {
    return {
        type: actionTypes.TERMS_ACCEPTED,
    }
};
export const authCheckState = () => {
    logger.debug('authCheckState start');
    return dispatch => {
        const accessToken = localStorage.getItem('access_token');
        logger.debug('authCheckState access token ',accessToken);
        if (!accessToken) {
            logger.debug('authCheckState access token not set case');
            dispatch(logout());
        } else {
            const expirationDate = new Date(localStorage.getItem('authExpirationDate'));
            logger.debug('authCheckState check expiration date ',expirationDate,new Date());
            if (expirationDate < new Date()) {
                logger.debug('authCheckState expired token case');
                dispatch(logout());
            } else {
                logger.debug('authCheckState valid token case');
                dispatch(authSuccess({
                    access_token: accessToken,
                    ecomm_id: localStorage.getItem('ecomm_id'),
                    hasAccount: localStorage.getItem('hasAccount')
                }));
                dispatch(checkAuthTimeOut((expirationDate.getTime() - new Date().getTime()) / 1000));
            }
        }
    };
};
