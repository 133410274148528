import * as actionTypes from '../actions/actionTypes';
import {updateObject} from "../utility";

const initialState = {
    error: false,
    loading: false,
    profileWasUpdated: false,
    profileInfo: {kids: []},
    dataToUpdate: null

};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_PROFILE_START: return updateObject(state, { loading: true, error: null, profileWasUpdated: false });
        case actionTypes.UPDATE_PROFILE_FAILED: return updateObject(state,{ error: action.error, loading: false, profileWasUpdated: false });
        case actionTypes.UPDATE_PROFILE_SUCCESS: return updateObject(state, {
            error: null,
            loading: false,
            profileWasUpdated: true,
            profileInfo: {
                ...state.profileInfo,
                ...action.dataToUpdate
            }
        });

        case actionTypes.GET_PROFILE_START: return updateObject(state, { loading: true});
        case actionTypes.GET_PROFILE_FAILED: return updateObject(state,{ error: action.error, loading: false});
        case actionTypes.GET_PROFILE_SUCCESS: return updateObject(state, {
            error: null,
            loading: false,
            profileInfo: action.profileInfo
        });

        case actionTypes.UPDATE_PROFILE_CLEAR_ERROR: return updateObject(state, { error: null });

        default: return state;
    }
};

export default reducer;