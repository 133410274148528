import * as actionTypes from './actionTypes';
import axios from 'axios';
import {TRACKING_URL} from "./axiosHelper";
import logger from "../../utils/logger";

export const sendTrackingStart = () => {
    return {
        type: actionTypes.SEND_TRACKING_STARTED,
    }
};
export const sendTrackingFailed = () => {
    return {
        type: actionTypes.SEND_TRACKING_FAILED,
    }
};

export const sendTrackingSuccess = () => {
    return {
        type: actionTypes.SEND_TRACKING_SUCCESS,
    }
};

export const sendTracking = (trackingData) => {
    return dispatch => {
        dispatch(sendTrackingStart());
        axios.post(TRACKING_URL, trackingData).then(response => {
            logger.debug(response);
            dispatch(sendTrackingSuccess(response.data));
        }).catch(error => {
            logger.debug(error);
            dispatch(sendTrackingFailed(error.error));
        });
    };
};